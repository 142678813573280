import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import {
  Customer,
  CustomerCollection,
  CustomerData,
} from "@planetadeleste/vue-mc-gw";
import { ResultData } from "vue-mc";
import { delay, toString } from "lodash";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Customer, CustomerCollection>
>;

@Component
export default class CustomersMixin extends Mixins(TypedModelMixin) {
  obCollection = new CustomerCollection();
  obModelClass = Customer;
  obCollectionClass = CustomerCollection;
  sRoutePath = "/customers";

  created() {
    this.onCreated();
  }

  async saved(obResponse: ResultData<CustomerData>) {
    if (this.continueEditing && obResponse.data.id) {
      this.continueEditing = false;
      await this.$router.push({
        name: "customers.update",
        params: { id: toString(obResponse.data.id) },
      });
    }
  }

  onRegisterEvents() {
    this.addEvent("after.hide.form", () => {
      delay(() => {
        this.emit("reload.index");
      }, 1500);
    });
  }
}
